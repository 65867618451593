.plan-d2{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}
.plan-d3{
    width: 80%;
}

.choose-plan-h1{
    color: #000000;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5em;
 text-align: center;
}
.plan-d5{
    position: relative;
    width: 400px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.plan-card-img{
    width: 400px;
    height: 150px;
    object-fit: cover;
    filter: grayscale(0.3);
}
.card-title{
    font-size: 25px ;
    text-align: center;
    text-transform: uppercase;
}
.ul-card-plan{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    gap: 20px;

}


.card-li-price{

    font-size: 12px;

    text-transform: uppercase;
  

}

.plan-d6{
    padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
}
.join-btn-card{
    padding: 10px 40px;
    background-color: #ea1b29;
    color: white;
    border-radius: 50px;
    text-transform: uppercase;
}

.plan-d4{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 50px 0px;
    gap: 10px;
}

.plan-d8{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan-d8>h1{
    font-size: 25px;
    text-align: center;


    text-transform: uppercase;
}



.plan-d8>p{
    text-align: center;
    color: gray;
    font-size: 14px;
    text-transform: uppercase;
}


@media(max-width:1540px) {

    .plan-d3 {
        width: 95%;
    }
    .plan-d4 {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 50px 0px;
        gap: 10px;
    }
}


@media(max-width:860px) {
    .plan-d5 {
        position: relative;
        width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .plan-card-img {
        width: 100%;

        height: 150px;
        object-fit: cover;
        filter: grayscale(0.3);
    }
 
}

@media(max-width:400px) {
    .plan-d8>h1 {
        font-size: 20px;
        
    }

    .choose-plan-h1 {
     
        font-size: 25px;
   
    }
    .plan-d8>p {
        text-align: center;
        color: gray;
        font-size: 12px;
    }

}


