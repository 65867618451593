.about-d1{

}
.about-d2{
    background-image: url('../../assets/Img/img4.jpg');
    height: 40vh;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-d2>h1{

    
    color: #FFFFFF;
    font-family: "Oswald", Sans-serif;
    font-size: 64px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
    
}
.about-d3{
width: 100%;
margin: 100px 0px;
display: flex;

justify-content: center;
}
.about-d4{
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.lh-about{
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lh-about>h1{
    color: #000000;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
}


.lh-about>p{
    text-transform: uppercase;
    color: gray;

}

.rh-about{
    width: 500px;
}
.rh-about>img{
    width: 100%;
    height: 500px;
    object-fit: cover;
}

@media(max-width:1450px) {
    .about-d4 {
        width: 95%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


}


@media(max-width:1170px) {
    .about-d4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    
    }
    .rh-about {
        width: 100%;
    }
    .lh-about {
        width: 100%;

    }
    

}
@media(max-width:700px) {


.about-d3{

    margin: 50px 0px;

    }
    .about-d2>h1 {

        font-size: 30px;
      
    }
}

