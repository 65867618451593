.img-health1{

    
    height: 10vh;
    width: 100%;
    object-fit: cover;
}

.health-d1{

    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0px;
}

.health-d2{

    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.health-lh{
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    align-items: start;
}

.health-lh>h1{
    color: #000000;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5em;
}
.health-lh>p{
    font-size: 14px;
    /* font-weight: 300; */
    text-transform: uppercase;
}


.health-lh>button{
    padding: 10px 30px;
    background: #ea1b29;
    color: white;
}
.health-rh{
width: 600px;
}
.health-rh>img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    filter: grayscale(0.3);
}


@media(max-width:1500px) {


    .health-d2 {
        width: 95%;

    }

}



@media(max-width:1150px) {
    .health-d2 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        gap: 10px;
    }
    .health-lh {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: start;
    }

    .health-rh {
        width: 100%;
;
    }

}


@media(max-width:500px) {

    .health-d1 {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 50px 0px;
    }
    .health-lh>h1 {
        color: #000000;
        font-family: "Oswald", Sans-serif;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        

}
.plan-d8>h1 {
    font-size: 15px !important;
    text-align: center;
    text-transform: uppercase;
}
}