.footer-d1{
    background-color: black;
    color: white;
    padding: 50px;
}
.footer-d2{
    width: 100%;
    display: flex;
    justify-content: center;
}
.footer-d3{
    width: 90%;
    color: white;
    
}
.footer-d4{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

}
.footer-d5{
width: 400px;
display: flex;
flex-direction: column;
gap: 20px;
}

.logo-d-footer{

}

.p-1-footer{
    font-size: 15px;
    text-transform: uppercase;
}

.social-links{
    display: flex;
    gap: 10px;
}
.social-icon>a{

    display: flex;
    background: white;
    padding: 8px;
    color: black;
    border-radius: 50px;
}
.footer-d6>h1{

    
    color: #ea1b29;
    font-family: "Oswald", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 2px;
}
.footer-ul{
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    
}
.footer-d6{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-link{
display: flex;
gap: 10px;
align-items: center;
}

@media(max-width:1000px) {

    .footer-d3 {
        width: 95%;
        color: white;
    }

}

.copy-right-footer{
    display: flex;
    justify-content: space-between;
    padding: 40px 0px;
}
.copy-right-footer>p{
text-transform: uppercase;
font-size: 15px;
}
.p-a-f1>a{
color: #ea1b29;
}


@media(max-width:500px) {

    .footer-d1 {
        background-color: black;
        color: white;
        padding: 10px;
    }

}
@media(max-width:715px) {


    .copy-right-footer {
        display: flex;
        justify-content: space-between;
        padding: 40px 0px;
        flex-direction: column;
        align-items: center;
    }
    .copy-right-footer>p {
        text-transform: uppercase;
        font-size: 12px;
    }
}


.logo-d-footer>h1{
    font-size: 25px;
    text-transform: uppercase;
}