.ch1-bg-1{
    background-image: url('../../assets/Img/contact.JPG');
    padding: 50px;
    height: 60vh;
    background-position: 50% 54%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hc-2-d1>h1{
    color: white;
    font-size: 50px;
}

.hc-2-d1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.hc-2-d1>button{
    padding: 10px 20px;
border-radius: 5px;
background-color: #ea1b29;
color: white;
text-transform: uppercase;
}

.hc-d1{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    gap: 38px;
}

.hc-d2{
    width: 100%;
    display: flex;
    justify-content: center;

}
.hc-d3 {
    width: 95%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
}
.hc2-heading{
    text-align: center;
    color: #000000;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
}

.cards-hc3{
    width: 350px;
    background-color: black;
    color: white;
}

.cards-hc3>img{
    width: 350px;
    filter: grayscale(0.3);
    height: 400px;
    object-fit: cover;
}
.card-data-hc3{
    padding: 40px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}
.card-data-hc3>h1{
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
font-size: 21px;

}
.card-data-hc3>p{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
    font-weight: 300;
}
.card-data-hc3>button{

    color: white;
   background-color: #ea1b29;
   padding: 10px 30px;
   font-size: 15px;
   text-transform: uppercase;

}

.hc-4-d1{
position: relative;
}

.video-hc4{
    width: 100%;
    /* height: 100%; */
    filter: grayscale(0.1);
}

.hc-4-d3{
    position: absolute;
    bottom: 50px;
    left: 80px;

}
.hc-4-d3>h1{
    color: white;
    font-family: "Oswald", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 2px;

}

.hc-4-d3{
    color: white;
display: flex;
gap: 20px;
align-items: center;
}

.hc-4-d3>button{
    background-color: #ea1b29;
    padding: 5px 20px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
display: flex;
align-items: center;
gap: 10px;
}



.hc-6-1{
    background: black;
    color: white;
    margin: 50px 0px;
    padding: 50px 0px;
}

.hc-6-h1{

  
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
  }
  .bmr-bmi-form-d1{
  width: 100%;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  }
  
  .bmr-d1{
    width: 80%;
    display: flex;
    padding: 0px 0px 50px 0px;
    justify-content: space-around;
    align-items: center;
  }
  
  .form-rh{
  width: 500px;
  }
  
  
  .bmr-head-h-p>h1{
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 65px;
  }
  .bmr-head-h-p>p{
    color: gray;
    font-size: 15px;
  }
  
  
  .div-input-bmr{
  display: flex;
  justify-content: space-between;
  }
  
  .input-bmr-div{
  width: 48%;
  display: flex;
  }
  .input-bmr-div>input{
    width: 100%;
    padding: 10px;
    white-space: nowrap;
  }
  
  .input-bmr-div>select{
    width: 100%;
    padding: 10px;
    white-space: nowrap;
  }
  
  
  .form-bmr{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  
  .form-lh {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  
  .btn-cal-1{
    background: white;
    color: black;
    padding: 14px 50px;
    border-radius: 5px;
  }
  
  
  .btn-cal-1:hover{
  
    background: gray;
    transition: all 0.3s ease;
  
  }
  
  
  
  .bmr-result1{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bmr-result2{
    
  }
  .bmr-result3>h3{
    font-size: 16px;
  }
  
  /* App.css */
  
  .table-container {
    margin: 20px;
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 16px;
  }
  
  .styled-table th,
  .styled-table td {
    /* border-bottom: 1px solid #ddd; */
    padding: 8px;
    text-align: left;
    padding: 17px;
  }
  
  .styled-table th {
  
    font-weight: bold;
  }
  
  .styled-table td:nth-child(1),
  .styled-table th:nth-child(1),
  .styled-table td:nth-child(1),
  .styled-table th:nth-child(1) {
    border-right: 1px solid #ddd; /* Add border between Name and Age columns */
  }
  
  
  
  .styled-table th,
  .styled-table tr {
    border-bottom: 1px solid #ddd;
  
  }
  .table-rh-h1{
    color: rgba(128, 128, 128, 0.709);
    font-size: 15px;
    text-align: center;
  }
  
  .table-rh-h1>span{
  color: black;
  }
  

  .hc6-h1{

    
    text-align: center;
    color: #000000;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
  }

  .hc-6-d2{
width: 100%;
display: flex;
justify-content: center;
  }

  .hc-6-d3{
    width: 80%;
  }
  .trans-images{
    width: 300px;
    height: 300px;
    object-fit: cover;
    cursor: pointer;
   
  }
  .hc-6-d4{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  .hc6-h1{
    padding: 0px 0px 50px 0px;
  }

  .hc7-d1{
    background-color: black;
    color: black;
    margin: 50px 0px;
    padding: 50px;
  }

  .hc7-h1{
      
    text-align: center;
    color: white;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
  }

  .hc7-d2{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .hc7-d3{
    width: 80%;
    color: white;
  }

  .hc7-d4{
    display: flex;
    justify-content: space-between;
    padding: 25px 0px;
    flex-wrap: wrap;
  }

  .hc7-d5{
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .hc7-d5>h1{
    font-size: 17px;
    letter-spacing: 2px;
    color: #ea1b29;
  }

  .count-no{
    font-size: 50px;
  }

  .plus-count{
    display: flex;
    align-items: center;
    font-size: 25px;

    gap: 5px;
  }


  @media(max-width:1550px) {
    .hc-d3 {
    width: 95%;
      display: flex;
      justify-content: space-around;
  }
  .bmr-d1 {
    width: 95%;
    
}
.hc-6-d3 {
  width: 95%;
}
.hc7-d3 {
  width: 95%;

}
  }

  @media(max-width:1300px) {
    .trans-images {
      width: 200px;
      height: 200px;
  }

  }






  @media(max-width:900px) {

    .bmr-d1 {
      display: flex;

      flex-direction: column;
      align-items: center;
  }

  .form-rh {
    width: 100%;
}
.form-lh {
  width: 100%;

}
.cards-hc3 {
  width: 100%;
}
.cards-hc3>img {
width: 100%;
object-fit: cover;
filter: grayscale(0.3);
height: 400px;
}

  }

  @media(max-width:700px) {


    .hc2-heading {

      font-size: 25px;

  }
  .hc-6-h1 {
    font-size: 25px;

}


.bmr-head-h-p>h1 {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 30px;
}
.hc6-h1 {
  font-size: 25px;

}


.hc7-h1 {
  font-size: 25px;

}
.hc-2-d1>h1 {
  color: white;
  font-size: 30px;
}

.ch1-bg-1 {

  padding: 30px;
  height: 38vh;
  background-position: 50% 54%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* .count-no {
    font-size: 30px;
}
.plus-count>h1{
  font-size: 25px;
} */
.hc7-d1 {
  background-color: black;
  color: black;
  margin: 50px 0px;
  padding: 30px 10px;
}
.hc2-heading {

  padding: 5px;
}

  .hc6-h1 {
    padding: 0px 5px 50px 5px;
}
.hc-6-h1 {
  padding: 5px;

}


  }

  @media(max-width:670px) {
    .trans-images {
      width: 150px;
      height: 150px;
  }
  .hc-4-d3 {
    position: absolute;
    bottom: 50px;
    /* left: 80px; */
    width: 100%;
left: unset;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
  }

  @media(max-width:495px) {
    .trans-images {
      width: 120px;
      height: 120px;
  }
  .hc-2-d1>h1 {
    color: white;
    font-size: 18px;
}
  }


  @media(max-width:450px) {
    .hc7-h1 {
      font-size: 20px;

  }
    .hc6-h1 {
      font-size: 20px;

  }
    .hc-6-h1 {
      font-size: 20px;
  }
    .trans-images {
      width: 100px;
      height: 100px;
  }
  .hc7-d4 {

    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

  }