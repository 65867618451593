*{
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');

ul{
  list-style: none;
}



button{
  background: transparent;
  border: none;
  cursor: pointer;
}




.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top.show {
  opacity: 1;
}

.scroll-to-top-btn {
  background-color: black;
  color: white;
  padding: 8px;
  display: flex;
  border: 1px solid white;

  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.scroll-to-top-btn:hover {

}
