.work-d1{
   padding: 100px 50px;
    background-color: black;
    color: white;
    margin-bottom: 50px;
}

.work-heading{
    color: #FFFFFF;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5em;
     text-align: center;
}

.work-d2{

display: flex;
justify-content: center;
width: 100%;
}

.work-d3{
    width: 80%;

    
}

.work-d4 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px 0px;
    gap: 10px;
    justify-content: space-between;
}

.work-d5 {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.work-d5>img{
width: 75px;
}
.work-d5>h1 {
    font-size: 30px;
}
.work-d5>p{
    text-align: center;
    font-size: 13px;

}


@media(max-width:1630px) {


    .work-d3 {
        width: 95%;
    }

}


@media(max-width:970px) {


    .work-d5 {
        width: 258px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .work-d5>img {
        width: 50px;
    }
    .work-d5>h1 {
        font-size: 20px;
    }
    .work-d5>p {
        text-align: center;
        font-size: 10px;
    }
}

@media(max-width:670px) {

    .work-d4 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 50px 0px;
        gap: 10px;
        justify-content: space-between;
        flex-direction: column;
    }

    .work-d5 {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}