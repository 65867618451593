.navbar-d1{
position: fixed;
width: 100%;
color: white;
z-index: 5000;
}

.logo-h1-1{
font-size: 20px;
text-transform: uppercase;
cursor: pointer;
}
a{
    text-decoration:none;
    color: white;
}




.navbar-d2{
    display: flex;
    justify-content: space-between;
    padding: 30px 100px;
    align-items: center;
}

.nav-ul1{
    display: flex;
    gap: 15px;
}

.nav-ul1>li{
    font-size: 15px;
    text-transform: uppercase;
}

.li-navi>a:hover{
    color: #ea1b29 !important ;

}



.navi-true{
    background: black;
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes slideInDown {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    }
    @keyframes slideInDown {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    } 




@media(max-width:900px) {

    .navbar-d2{
     
        padding: 30px 50px;
     
    }

}

.mobile-bar {

    display: none;
}
@media(max-width:600px) {
    .mobile-bar {

        display: unset;
    }
    .nav-ul1 {
       display: none;
    }
    .navbar-d2 {
        padding: 30px;
    }
}

.mobile-bar{
    
}

.ul-nav-2{
display: flex;
flex-direction: column;
gap: 20px;
}
.side-menu{
    padding: 50px 30px;
}

.nav-li2{
    font-size: 20px;
    text-transform: uppercase;
}




/* Dropdown container styles */
.dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  /* Button styles */
  .dropdown-button {
    font-size: 15px;
    text-transform: uppercase;
    color: white;

    border: none;
    cursor: pointer;
  }
  
  /* Dropdown content (hidden by default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  /* Show the dropdown content when the button is hovered */
  .dropdown-container:hover .dropdown-content {
    display: block;
  }
  
  /* Dropdown items */
  .dropdown-content p {
    color: black;
    padding: 12px 14px;
    text-decoration: none;
    font-size: 13px;
    display: block;
  }
  
  /* Dropdown item hover effect */
  .dropdown-content p:hover {
    background-color: #f1f1f1;
  }
  
  .ul-mb-1{
display: flex;
flex-direction: column;
gap: 10px;

  }

  .ul-mb-1{
    color: white;
      }


  .ul-mb-1>li>a{
    font-size: 15px;    

      }

      .nav-li2 {
 cursor: pointer;
}

.option-open-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}