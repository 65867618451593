
.ebook2{
    background-image: url('../../assets/Img/img3.jpeg');
    height: 40vh;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ebook2>h1{

    
    color: #FFFFFF;
    font-family: "Oswald", Sans-serif;
    font-size: 64px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
    
}


.ebook5{
 
}
.ebook5>h1{
    text-align: center;
    color: #000000;
    font-family: "Oswald", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
}


.ebook6{
    padding: 50px 0px;
}

.book3{
width: 100%;
display: flex;
justify-content: center;
padding: 50px 0px;
}

.book4{
    width: 95%;
}

.book-card1{
    width: 350px;
  
    padding: 10px;
    border-radius: 5px;
   border: 1px solid rgba(128, 128, 128, 0.531);

}
.card-bookd2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.531);
    padding-bottom: 20px;
}
.headcard-book{
    font-size: 15px;
    font-weight: 100;
 
}

.price-book>h1{
    font-size: 30px;

}
.price-book>p{
    font-size: 15px;
    font-weight: 100;
color: gray;
}

.card-min1{
    border: 1px solid rgba(128, 128, 128, 0.531);

    padding: 5px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.price-book{
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;
   
}

.card-book-ul{
padding: 20px 0px;
display: flex;
flex-direction: column;
gap: 20px;
}

.card-li-book{
    text-transform: uppercase;
    display: flex;
    letter-spacing: 2px;
    gap: 10px;
    align-items: center;
    font-size: 14px;
}

.choose-btn-book>button{
    background-color: #ea1b29;
    width: 100%;
    padding: 10px;
    color: white;
    border-radius: 5px;
}

.book7{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}


@media(max-width:1200px) {

    .book-card1 {
        width: 250px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(128, 128, 128, 0.531);
    }

}

@media(max-width:880px) {

    .book-card1 {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(128, 128, 128, 0.531);
    }


.ebook5>h1 {
 
    font-size: 30px;

 
}
}



@media(max-width:700px) {


 
    .ebook2>h1 {

        font-size: 30px;

      
    }
}


@media(max-width:600px) {


 
    .ebook5>h1 {
        font-size: 20px;
    }
}