.policy-d2{
    
    background-position: top;
    background-size: cover;
height: 30vh;
display: flex;
align-items: center;
justify-content: center;
    background-image: url('https://images.unsplash.com/photo-1544021601-3e5723f9d333?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTQ0fHxib2R5YnVpbGRpbmd8ZW58MHx8MHx8fDA%3D');
}

.policy-d3{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.policy-d3>h1{
  color: white;
}

.policy-d3>h2{
    color: white;
  
}

.policy-d4{
    padding: 50px 0px;
    width: 100%;
    display: flex;
    justify-content: center;

}

.policy-d5{

    width: 80%;
}


.policy-d6 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.policy-d6>h1{
    font-size: 25px;
}


.policy-d6>p{
    font-size: 15px;
    color: #000;
    text-transform: uppercase;
}

.policy-d3>h1 {
    color: white;
    font-size: 35px;
}
.policy-d3>h2 {
    color: white;
    font-size: 20px;
}


.policy-d6>h2 {
    font-size: 20px;
}


@media(max-width:700px) {
    .policy-d3>h1 {
        color: white;
        font-size: 25px;
    }
    .policy-d3>h2 {
        color: white;
        font-size: 15px;
    }
    .policy-d5 {
        width: 95%;
    }
    .policy-d6>h1 {
        font-size: 20px;
    }
}