
.contact-d2{
    background-image: url('../../assets/Background/bg1.jpeg');
    height: 40vh;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.contact-d2>h1{

    
    color: #FFFFFF;
    font-family: "Oswald", Sans-serif;
    font-size: 64px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5em;
    letter-spacing: 5px;
    
}

.contact-map{
    width: 100%;
    height: 60vh;
    margin-top: 5px;

}
.contact-map>iframe{
    width: 100%;
    height: 100%;
    border: 0;
}

.contact-d3{
    margin: 50px 0px;
    background-color: black;
    color: white;
    padding: 50px; 
    text-align: center;
}
.contact-d3>h1{


font-size: 35px;
text-transform: uppercase;
}


.contact-d3>h2{
    font-size: 25px;
text-transform: uppercase;
}


@media(max-width:700px) {


 
    .contact-d2>h1 {

        font-size: 30px;

      
    }
}
@media(max-width:600px) {


    .contact-d3>h1 {
        font-size: 20px;
        text-transform: uppercase;
    }
    .contact-d3>h2 {
        font-size: 15px;
        text-transform: uppercase;
    }
    }